var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as yup from 'yup';
import { useField } from 'vee-validate';
import { defineComponent, inject, computed, ref, onMounted, } from 'vue';
import { useRouter } from 'vue-router';
import { IonContent, IonCard, IonCardContent, IonCardHeader, IonPage, IonInput, IonItem, IonLabel, IonButton, toastController, } from '@ionic/vue';
import { onBeforeRouteLeave, useRoute } from 'vue-router';
import i18n from '@/i18n';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import Swal from 'sweetalert2';
var componentName = 'ForgotPassword';
var components = {
    IonContent: IonContent,
    IonCard: IonCard,
    IonCardContent: IonCardContent,
    IonCardHeader: IonCardHeader,
    IonPage: IonPage,
    IonInput: IonInput,
    IonItem: IonItem,
    IonLabel: IonLabel,
    IonButton: IonButton,
};
var setup = function () {
    var router = useRouter();
    var authStore = inject('authState');
    var showError = computed(function () { return authStore.state.resetPassword.showError; });
    var errorMessage = computed(function () { return authStore.state.resetPassword.errorMessage; });
    var showNewPassword = ref(false);
    var route = useRoute();
    var userId = ref('');
    var codeAction = ref('');
    var confirmPassword = ref('');
    var _a = useField('email', yup
        .string()
        .email(i18n.global.t('WRONG_EMAIL_FORMAT'))
        .required(i18n.global.t('FIELD_REQUIRED'))), errorMail = _a.errorMessage, email = _a.value;
    var _b = useField('password', yup
        .string()
        .min(6, i18n.global.t('WRONG_PASSWORD_FORMAT'))
        .required(i18n.global.t('FIELD_REQUIRED'))), passwordError = _b.errorMessage, password = _b.value;
    var formValid = computed(function () { return !!email.value && !errorMail.value; });
    var passwordFormValid = computed(function () { return !!password.value && !passwordError.value; });
    var _showToast = function () { return __awaiter(void 0, void 0, void 0, function () {
        var toast;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, toastController.create({
                        color: 'primary',
                        duration: 2000,
                        message: i18n.global.t('tester_email_recovery_password'),
                        position: 'top',
                    })];
                case 1:
                    toast = _a.sent();
                    return [4 /*yield*/, toast.present()];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var resetPassword = function () { return __awaiter(void 0, void 0, void 0, function () {
        var success;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!formValid.value)
                        return [2 /*return*/];
                    return [4 /*yield*/, authStore.resetPassword(email.value)];
                case 1:
                    success = _a.sent();
                    if (!success) {
                        return [2 /*return*/];
                    }
                    _showToast();
                    return [2 /*return*/];
            }
        });
    }); };
    var handlePause = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!Capacitor.isNativePlatform()) return [3 /*break*/, 2];
                    return [4 /*yield*/, authStore.logUserOut()];
                case 1:
                    _a.sent();
                    goTo('login');
                    App.removeAllListeners();
                    App.exitApp();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var goTo = function (url) {
        router.push({ name: url });
    };
    var checkResetCode = function () { return __awaiter(void 0, void 0, void 0, function () {
        var chechkResetCodeData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    chechkResetCodeData = {
                        userId: userId.value,
                        data: {
                            codeAction: codeAction.value,
                        },
                    };
                    return [4 /*yield*/, authStore.checkResetCode(chechkResetCodeData)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var setNewPassword = function () { return __awaiter(void 0, void 0, void 0, function () {
        var resetNewPasswordData, success;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!passwordFormValid.value)
                        return [2 /*return*/];
                    if (password.value !== confirmPassword.value) {
                        authStore.state.resetPassword.showError = true;
                        authStore.state.resetPassword.errorMessage = i18n.global.t('tester_email_recovery_password');
                        return [2 /*return*/];
                    }
                    resetNewPasswordData = {
                        userId: userId.value,
                        data: {
                            password: password.value,
                            codeAction: codeAction.value,
                        },
                    };
                    return [4 /*yield*/, authStore.resetNewPassword(resetNewPasswordData)];
                case 1:
                    success = _a.sent();
                    if (success) {
                        Swal.fire({
                            title: i18n.global.t('PASSWORD_UPDATED'),
                            text: i18n.global.t('USE_NEW_PASSWORD'),
                            icon: 'success',
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'Vale',
                            showCancelButton: false,
                            allowOutsideClick: false
                        }).then(function (result) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                router.push({ name: 'login' });
                                return [2 /*return*/];
                            });
                        }); });
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    onMounted(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(route.query.userId != undefined &&
                        route.query.codeAction != undefined)) return [3 /*break*/, 2];
                    userId.value = route.query.userId;
                    codeAction.value = route.query.codeAction;
                    showNewPassword.value = true;
                    return [4 /*yield*/, checkResetCode()];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    if (Capacitor.isNativePlatform()) {
                        App.addListener('pause', handlePause);
                    }
                    return [2 /*return*/];
            }
        });
    }); });
    onBeforeRouteLeave(function () {
        authStore.state.resetPassword.showError = false;
        authStore.state.resetPassword.errorMessage = '';
    });
    return {
        email: email,
        errorMail: errorMail,
        showError: showError,
        errorMessage: errorMessage,
        formValid: formValid,
        passwordFormValid: passwordFormValid,
        resetPassword: resetPassword,
        showNewPassword: showNewPassword,
        setNewPassword: setNewPassword,
        password: password,
        passwordError: passwordError,
        confirmPassword: confirmPassword,
    };
};
export default defineComponent({ name: componentName, components: components, setup: setup });
